<template>
  <b-row>
    <b-col cols="12" md="9">
      <b-card title="Previzualitzacions">
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="scroll-area overflow-hidden"
        >
          <invoice-preview
            v-for="(invoicePreview, index) in invoicePreviews"
            :key="`preview-${index}`"
            :invoice-preview="invoicePreview"
            @comment-updated="onCommentUpdated"
          />
        </vue-perfect-scrollbar>
      </b-card>
    </b-col>

    <b-col cols="12" md="3">
      <b-card title="Accions">
        <b-button
          variant="primary"
          @click="onGenerateBillingButtonClicked"
          block
        >
          Generar Factures i Liquidacions
        </b-button>
      </b-card>
    </b-col>

    <b-modal
      id="send-invoice-modal"
      size="lg"
      title="Envia les factures"
      centered
      hide-footer
    >
      <send-invoice-table />
    </b-modal>
  </b-row>
</template>

<script>
import { BButton, BModal, BCard, BRow, BCol } from "bootstrap-vue";
import InvoicePreview from "@/views/accounting/billing/details/components/InvoicePreview.vue";
import SendInvoiceTable from "@/views/accounting/billing/details/components/SendInvoiceTable.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
  AGENCY_LEGAL_NAME,
  AGENCY_LEGAL_ADDRESS,
  AGENCY_CIF,
  AGENCY_PHONE,
  AGENCY_CLIENT_INVOICES_SERIES,
  AGENCY_OWNER_INVOICES_SERIES,
} from "@appConfig";
import { getFullAddress, notifyError, notifySuccess } from "@/utils/methods";
import { formatDateStringToDatabaseDate } from "@/utils/formatters";

export default {
  components: {
    SendInvoiceTable,
    InvoicePreview,
    BButton,
    BModal,
    BCard,
    BRow,
    BCol,
    VuePerfectScrollbar,
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      invoicePreviews: [],
    };
  },
  computed: {
    agencyToClientSelectedPendingInvoiceLines() {
      return this.$store.getters[
        "billingProcess/agencyToClientSelectedPendingInvoiceLines"
      ];
    },
    agencyToOwnerSelectedPendingInvoiceLines() {
      return this.$store.getters[
        "billingProcess/agencyToOwnerSelectedPendingInvoiceLines"
      ];
    },
    ownerSettlementSelectedPendingInvoiceLines() {
      return this.$store.getters[
        "billingProcess/ownerSettlementSelectedPendingInvoiceLines"
      ];
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    databaseBookingCheckin() {
      return (
        formatDateStringToDatabaseDate(this.booking?.checkin) || "No definit"
      );
    },
    databaseBookingCheckout() {
      return (
        formatDateStringToDatabaseDate(this.booking?.checkout) || "No definit"
      );
    },
    contract() {
      return this.$store.getters["booking/contract"];
    },
    accommodation() {
      return this.contract?.accommodation || null;
    },
    accommodationName() {
      return this.accommodation?.name || null;
    },
    accommodationAddress() {
      return getFullAddress(this.accommodation?.location) || null;
    },
    accommodationTouristLicenceNumber() {
      return this.accommodation?.touristLicenceNumber || null;
    },
    client() {
      return this.$store.getters["booking/client"];
    },
    clientFirstName() {
      return this.client?.firstName || null;
    },
    clientLastName() {
      return this.client?.lastName || null;
    },
    clientFullName() {
      return this.client?.fullName || null;
    },
    clientIdType() {
      return this.client?.idType || null;
    },
    clientIdNumber() {
      return this.client?.idNumber || null;
    },
    clientAddress() {
      return getFullAddress(this.client?.location);
    },
    clientPhone() {
      return this.client?.phone || null;
    },
    owner() {
      return this.contract?.owner || null;
    },
    ownerFirstName() {
      return this.owner?.firstName || null;
    },
    ownerLastName() {
      return this.owner?.lastName || null;
    },
    ownerFullName() {
      return this.owner?.fullName || null;
    },
    ownerAddress() {
      return getFullAddress(this.owner?.location) || null;
    },
    ownerIdType() {
      return this.owner?.idType || null;
    },
    ownerIdNumber() {
      return this.owner?.idNumber || null;
    },
    ownerPhone() {
      return this.owner?.phone || null;
    },
  },
  created() {
    this.generateInvoicePreviews();

    // If there are no invoice previews, redirect to the generator
    if (!this.invoicePreviews.length) {
      const localizator = this.$route.params.localizator;

      this.$router.push({
        name: "foravila-accounting-billing-details",
        params: {
          localizator,
        },
      });
    }
  },
  destroyed() {
    this.$store.dispatch("billingProcess/reset");
  },
  methods: {
    generateInvoicePreviews() {
      this.invoicePreviews = [];

      // AGENCY TO CLIENT
      if (this.agencyToClientSelectedPendingInvoiceLines.length) {
        const invoiceLines = this.agencyToClientSelectedPendingInvoiceLines.map(
          (invoiceLine) => ({
            bookingService: invoiceLine.service || null,
            description: invoiceLine.description || null,
            basePrice: invoiceLine.baseAmount || 0,
            vatPrice: invoiceLine.vatAmount || 0,
            irpfPrice: invoiceLine.irpfAmount || 0,
            pvpPrice: invoiceLine.totalAmount || 0,
            type: invoiceLine.invoiceLineType || null,
          })
        );

        this.invoicePreviews.push({
          booking: this.booking["@id"],
          invoiceNumberingSeries: AGENCY_CLIENT_INVOICES_SERIES,
          holderIdType: "CIF",
          holderIdNumber: AGENCY_CIF,
          holderFirstName: AGENCY_LEGAL_NAME,
          holderLastName: null,
          holderAddress: AGENCY_LEGAL_ADDRESS,
          holderPhone: AGENCY_PHONE,
          recipientIdType: this.clientIdType,
          recipientIdNumber: this.clientIdNumber,
          recipientFirstName: this.clientFirstName,
          recipientLastName: this.clientLastName,
          recipientAddress: this.clientAddress,
          recipientPhone: this.clientPhone,
          accommodationName: this.accommodationName,
          accommodationAddress: this.accommodationAddress,
          accommodationTouristLicenceNumber:
            this.accommodationTouristLicenceNumber,
          bookingCheckin: this.databaseBookingCheckin,
          bookingCheckout: this.databaseBookingCheckout,
          bookingClientName: this.clientFullName,
          bookingClientIdNumber: this.clientIdNumber,
          comments: null,
          invoiceLines,
          //
          previewId: "agency-to-client",
          invoiceType: "AGENCY_TO_CLIENT",
          isSettlement: false,
        });
      }

      // AGENCY TO OWNER
      if (this.agencyToOwnerSelectedPendingInvoiceLines.length) {
        const invoiceLines = this.agencyToOwnerSelectedPendingInvoiceLines.map(
          (invoiceLine) => ({
            bookingService: invoiceLine.service || null,
            description: invoiceLine.description || null,
            basePrice: invoiceLine.baseAmount || 0,
            vatPrice: invoiceLine.vatAmount || 0,
            irpfPrice: invoiceLine.irpfAmount || 0,
            pvpPrice: invoiceLine.totalAmount || 0,
            type: invoiceLine.invoiceLineType || null,
          })
        );

        this.invoicePreviews.push({
          booking: this.booking["@id"],
          invoiceNumberingSeries: AGENCY_OWNER_INVOICES_SERIES,
          holderIdType: "CIF",
          holderIdNumber: AGENCY_CIF,
          holderFirstName: AGENCY_LEGAL_NAME,
          holderLastName: null,
          holderAddress: AGENCY_LEGAL_ADDRESS,
          holderPhone: AGENCY_PHONE,
          recipientIdType: this.ownerIdType,
          recipientIdNumber: this.ownerIdNumber,
          recipientFirstName: this.ownerFirstName,
          recipientLastName: this.ownerLastName,
          recipientAddress: this.ownerAddress,
          recipientPhone: this.ownerPhone,
          accommodationName: this.accommodationName,
          accommodationAddress: this.accommodationAddress,
          accommodationTouristLicenceNumber:
            this.accommodationTouristLicenceNumber,
          bookingCheckin: this.databaseBookingCheckin,
          bookingCheckout: this.databaseBookingCheckout,
          bookingClientName: this.clientFullName,
          bookingClientIdNumber: this.clientIdNumber,
          comments: null,
          invoiceLines,
          //
          previewId: "agency-to-owner",
          invoiceType: "AGENCY_TO_OWNER",
          isSettlement: false,
        });
      }

      // OWNER SETTLEMENT
      if (this.ownerSettlementSelectedPendingInvoiceLines.length) {
        const ownerLiquidationLines =
          this.ownerSettlementSelectedPendingInvoiceLines.map(
            (invoiceLine) => ({
              bookingService: invoiceLine.service || null,
              description: invoiceLine.description || null,
              basePrice: invoiceLine.baseAmount || 0,
              vatPrice: invoiceLine.vatAmount || 0,
              irpfPrice: invoiceLine.irpfAmount || 0,
              pvpPrice: invoiceLine.totalAmount || 0,
              type: invoiceLine.invoiceLineType || null,
            })
          );

        this.invoicePreviews.push({
          booking: this.booking["@id"],
          holderIdType: "CIF",
          holderIdNumber: AGENCY_CIF,
          holderFirstName: AGENCY_LEGAL_NAME,
          holderLastName: null,
          holderAddress: AGENCY_LEGAL_ADDRESS,
          holderPhone: AGENCY_PHONE,
          recipientIdType: this.ownerIdType,
          recipientIdNumber: this.ownerIdNumber,
          recipientFirstName: this.ownerFirstName,
          recipientLastName: this.ownerLastName,
          recipientAddress: this.ownerAddress,
          recipientPhone: this.ownerPhone,
          accommodationName: this.accommodationName,
          accommodationAddress: this.accommodationAddress,
          accommodationTouristLicenceNumber:
            this.accommodationTouristLicenceNumber,
          bookingCheckin: this.databaseBookingCheckin,
          bookingCheckout: this.databaseBookingCheckout,
          bookingClientName: this.clientFullName,
          bookingClientIdNumber: this.clientIdNumber,
          comments: null,
          ownerLiquidationLines,
          //
          previewId: "owner-settlement",
          invoiceType: "AGENCY_TO_OWNER",
          isSettlement: true,
        });
      }

      return this.invoicePreviews;
    },
    updateInvoicePreviewComments(previewId, comments) {
      const previewIndex = this.invoicePreviews.findIndex(
        (invoicePreview) => invoicePreview.previewId === previewId
      );
      if (previewIndex === -1) return;
      this.invoicePreviews[previewIndex].comments = comments;
    },
    onGenerateBillingButtonClicked() {
      this.$store.dispatch("app/setLoading", true);

      const promises = [];

      this.invoicePreviews.forEach((invoicePreview) => {
        if (invoicePreview.previewId !== "owner-settlement") {
          promises.push(this.generateInvoice(invoicePreview));
        } else {
          promises.push(this.generateSettlement(invoicePreview));
        }
      });

      Promise.all(promises)
        .then(() => {
          notifySuccess(
            "Factures generades",
            "Les factures s'han generat correctament"
          );

          this.$bvModal.show("send-invoice-modal");
        })
        .catch(() => {
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar generar les factures"
          );
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    onCommentUpdated(data) {
      const { previewId, comments } = data;
      this.updateInvoicePreviewComments(previewId, comments);
    },
    generateInvoice(invoiceData) {
      if (!invoiceData?.invoiceLines?.length) {
        notifyError("Error", "No hi ha linies de factura per facturar");
        return;
      }
      return this.$store.dispatch("booking/addInvoice", invoiceData);
    },
    generateSettlement(settlementData) {
      if (!settlementData?.ownerLiquidationLines?.length) {
        notifyError("Error", "No hi ha linies de factura per liquidar");
        return;
      }
      return this.$store.dispatch(
        "booking/addOwnerLiquidation",
        settlementData
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: calc(100vh - 20rem);
  height: calc(var(--vh, 1vh) * 100 - 20rem);
}
</style>
