<!-- eslint-disable vue/html-self-closing -->
<template>
  <div class="invoice-preview p-2 bg-white mb-4">
    <!-- HEADER -->
    <div
      class="d-flex justify-content-between align-items-center flex-md-row flex-column mb-2 mb-md-3"
    >
      <div class="order-1 order-md-0">
        <h2 v-if="invoicePreview.isSettlement">LIQUIDACIÓ</h2>
        <h2 v-else>FACTURA</h2>
        <div v-if="!invoicePreview.isSettlement" class="text-nowrap">
          <b>Nº:</b>
          <span class="text-muted">
            {{ invoicePreview.invoiceNumberingSeries }}XXXXXXXXXX
          </span>
        </div>
        <div class="text-nowrap">
          <b>Data d'emisió:</b>
          <span class="text-muted">
            {{ today }}
          </span>
        </div>
      </div>

      <div class="logo-wrapper order-0 order-md-1 mb-2 mb-md-0">
        <logo />
      </div>
    </div>

    <!-- HOLDER -->
    <div class="bg-light p-2 border mb-3">
      <b v-if="holderFullName">
        {{ holderFullName }}
      </b>
      <div v-if="invoicePreview.holderIdNumber">
        {{ invoicePreview.holderIdNumber }}
      </div>
      <div v-if="invoicePreview.holderAddress">
        {{ invoicePreview.holderAddress }}
      </div>
      <div v-if="invoicePreview.holderPhone">
        {{ invoicePreview.holderPhone }}
      </div>
    </div>

    <div class="d-flex justify-content-between flex-md-row flex-column">
      <!-- ACCOMMODATION -->
      <div class="mr-md-3 mb-3 mb-md-0">
        <h6 class="mb-1">Allotjament:</h6>
        <p v-if="invoicePreview.accommodationName" class="m-0">
          {{ invoicePreview.accommodationName }}
        </p>
        <p v-if="invoicePreview.accommodationTouristLicenceNumber" class="m-0">
          {{ invoicePreview.accommodationTouristLicenceNumber }}
        </p>
        <p v-if="invoicePreview.accommodationAddress" class="m-0">
          {{ invoicePreview.accommodationAddress }}
        </p>
      </div>

      <!-- RECIPIENT -->
      <div>
        <h6 v-if="invoicePreview.isSettlement" class="mb-1">Liquidat a:</h6>
        <h6 v-else class="mb-1">Facturat a:</h6>
        <p v-if="recipientFullName" class="m-0">
          {{ recipientFullName }}
        </p>
        <p v-if="invoicePreview.recipientIdNumber" class="m-0">
          {{ invoicePreview.recipientIdNumber }}
        </p>
        <p v-if="invoicePreview.recipientAddress" class="m-0">
          {{ invoicePreview.recipientAddress }}
        </p>
        <p v-if="invoicePreview.recipientPhone" class="m-0">
          {{ invoicePreview.recipientPhone }}
        </p>
      </div>
    </div>

    <!-- INVOICE LINES -->
    <div class="mx-1 mt-4">
      <!-- Header -->
      <b-row
        class="font-weight-bolder text-uppercase small bg-light py-1 mb-1 border"
      >
        <b-col cols="6"> Descripció </b-col>
        <b-col cols="2" class="text-right"> Base imponible </b-col>
        <b-col cols="1" class="text-right"> IVA </b-col>
        <b-col cols="1" class="text-right"> IRPF </b-col>
        <b-col cols="2" class="text-right"> Subtotal </b-col>
      </b-row>

      <!-- Invoice lines -->
      <b-row
        v-for="(invoiceLine, index) in invoicePreview.invoiceLines"
        :key="`invoice-line-${index}`"
        class="py-1"
      >
        <b-col cols="6">
          {{ invoiceLine.description }}
        </b-col>
        <b-col cols="2" class="text-right">
          {{ formatCurrency(invoiceLine.basePrice) }}
        </b-col>
        <b-col cols="1" class="text-right">
          {{ formatCurrency(invoiceLine.vatPrice) }}
        </b-col>
        <b-col cols="1" class="text-right">
          {{ formatCurrency(invoiceLine.irpfPrice) }}
        </b-col>
        <b-col cols="2" class="text-right">
          {{ formatCurrency(invoiceLine.pvpPrice) }}
        </b-col>
      </b-row>

      <!-- Footer -->
      <b-row
        class="font-weight-bolder text-uppercase mt-1 bg-light border py-1"
      >
        <b-col cols="6"> Total </b-col>
        <b-col cols="2" class="text-right font-weight-normal">
          {{ formatCurrency(basePriceSum) }}
        </b-col>
        <b-col cols="1" class="text-right font-weight-normal">
          {{ formatCurrency(vatPriceSum) }}
        </b-col>
        <b-col cols="1" class="text-right font-weight-normal">
          {{ formatCurrency(irpfPriceSum) }}
        </b-col>
        <b-col cols="2" class="text-right">
          {{ formatCurrency(pvpPriceSum) }}
        </b-col>
      </b-row>
    </div>

    <!-- NOTES -->
    <div class="mt-3">
      <b-form-textarea
        v-model="comments"
        placeholder="Notes"
        rows="3"
        @input="onCommentUpdated"
      />
    </div>
  </div>
</template>

<script>
import { BFormTextarea, BRow, BCol } from "bootstrap-vue";
import { formatCurrency } from "@/utils/formatters";
import Logo from "@/assets/foravila/images/logos/ForavilaRentalsLogo.vue";

export default {
  components: {
    Logo,
    BFormTextarea,
    BRow,
    BCol,
  },
  props: {
    invoicePreview: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      comments: null,
      formatCurrency,
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    today() {
      return this.$moment().startOf("day").format("DD/MM/YYYY");
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    contract() {
      return this.$store.getters["booking/contract"];
    },
    holderFullName() {
      const name = [];
      if (this.invoicePreview?.holderFirstName)
        name.push(this.invoicePreview?.holderFirstName);
      if (this.invoicePreview?.holderLastName)
        name.push(this.invoicePreview?.holderLastName);
      return name.length ? name.join(" ") : null;
    },
    recipientFullName() {
      const name = [];
      if (this.invoicePreview?.recipientFirstName)
        name.push(this.invoicePreview?.recipientFirstName);
      if (this.invoicePreview?.recipientLastName)
        name.push(this.invoicePreview?.recipientLastName);
      return name.length ? name.join(" ") : null;
    },
    basePriceSum() {
      if (!this.invoicePreview.invoiceLines?.length) {
        return null;
      }

      return this.invoicePreview.invoiceLines.reduce(
        (sum, row) => sum + row.basePrice,
        0
      );
    },
    vatPriceSum() {
      if (!this.invoicePreview.invoiceLines?.length) {
        return null;
      }

      return this.invoicePreview.invoiceLines.reduce(
        (sum, row) => sum + row.vatPrice,
        0
      );
    },
    irpfPriceSum() {
      if (!this.invoicePreview.invoiceLines?.length) {
        return null;
      }

      return this.invoicePreview.invoiceLines.reduce(
        (sum, row) => sum + row.irpfPrice,
        0
      );
    },
    pvpPriceSum() {
      if (!this.invoicePreview.invoiceLines?.length) {
        return null;
      }

      return this.invoicePreview.invoiceLines.reduce(
        (sum, row) => sum + row.pvpPrice,
        0
      );
    },
  },
  methods: {
    onCommentUpdated(comments) {
      this.$emit("comment-updated", {
        previewId: this.invoicePreview.previewId,
        comments,
      });
    },
  },
};
</script>

<style lang="scss">
.invoice-preview {
  border: 4px solid rgb(248, 248, 248);
  .logo-wrapper {
    width: 14rem;
  }
}
</style>
