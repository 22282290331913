<template>
  <div>
    <b-table striped borderless :items="items" :fields="fields">
      <template #cell(actions)>
        <feather-icon class="ml-75 cursor-pointer" icon="SendIcon" />
      </template>
    </b-table>
  </div>
</template>
<script>
import { BTable } from "bootstrap-vue";
export default {
  components: {
    BTable,
  },
  data() {
    return {
      fields: [
        { key: "invoice_number", label: "Factura" },
        { key: "invoice_recipient", label: "Destinatari" },
        { key: "actions", label: "Accions" },
      ],
      items: [
        {
          invoice_number: 123444,
          invoice_recipient: "Enric Fabin (client)",
        },
        {
          invoice_number: 212234,
          invoice_recipient: "Antoni Comes i Tauler (propietari)",
        },
      ],
    };
  },
};
</script>
